import styled from 'styled-components';

export const Section = styled.section`
  margin-bottom: 20px;
`;

export const SectionTitle = styled.h3`
  margin-bottom: 10px;
  color: #333;
`;

export const LabeledInput = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
`;

export const StyledLabel = styled.label`
  width: 140px;
  flex-shrink: 0;
  font-size: 14px;
`;

export const CompactInput = styled.input`
  width: 230px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const CompactSelect = styled.select`
  width: 230px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const CompactHorizontalGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const HorizontalRadioGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: #555;
`;

export const Textarea = styled.textarea`
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; // This ensures padding is included in the width
`;

export const FileUploadArea = styled.div`
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
`;

export const FileList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  width: 100%;
`;

export const FileItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
`;

export const RemoveButton = styled.button`
  background: none;
  border: none;
  color: red;
  cursor: pointer;
`;

export const SliderVariableControls = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 230px;
`;

export const SliderVariableSlider = styled.input`
  flex-grow: 1;
`;

export const SliderVariableInput = styled.input`
  width: 50px;
`;

export const SizeCustomizerInput = styled.input`
  width: 50px;
  margin-left: 6px;
  margin-right: 6px;
  padding: 2px;
`;

export const StateCurriculumValue = styled.div`
  width: 230px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
  font-size: 14px;
`;

export const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;
