import React from 'react';
import { useApiState } from '../../ApiStateContext';
import {
  Section,
  SectionTitle,
  StyledLabel,
  Textarea,
  FileUploadArea,
  FileList,
  FileItem,
  RemoveButton,
  TextAreaContainer
} from './SharedStyledComponents';

interface TutorTopicConfigProps {
  sessionId: string | null;
  pendingImages: File[];
  setPendingImages: React.Dispatch<React.SetStateAction<File[]>>;
  uploadedImages: string[];
  setUploadedImages: React.Dispatch<React.SetStateAction<string[]>>;
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleImageRemove: (url: string, e: React.MouseEvent<HTMLButtonElement>) => void;
  handlePendingImageRemove: (file: File, e: React.MouseEvent<HTMLButtonElement>) => void;
  handleImageDrop: (e: React.DragEvent<HTMLDivElement>) => void;
}

export const TutorTopicConfig: React.FC<TutorTopicConfigProps> = ({
  sessionId,
  pendingImages,
  uploadedImages,
  handleImageChange,
  handleImageRemove,
  handlePendingImageRemove,
  handleImageDrop
}) => {
  const { apiState, updateApiState } = useApiState();

  return (
    <Section>
      <SectionTitle>Tutor Topic</SectionTitle>
      <TextAreaContainer>
        <StyledLabel htmlFor="stimulusText">Topic Text</StyledLabel>
        <Textarea 
          id="stimulusText"
          name="stimulusText"
          value={apiState.stimulusText} 
          onChange={(e) => updateApiState({ stimulusText: e.target.value }, sessionId)}
          placeholder="Enter topic text..."
          rows={5}
        />
      </TextAreaContainer>
      <FileUploadArea
        onDrop={handleImageDrop}
        onDragOver={(e) => e.preventDefault()}
        onClick={() => {
          document.getElementById('fileInput')?.click();
        }}
      >
        {uploadedImages.length === 0 && pendingImages.length === 0 ? (
          "Drag and drop files here or click to upload"
        ) : (
          <FileList>
            {uploadedImages.map((file, index) => (
              <FileItem key={index}>
                <span>{file}</span>
                <RemoveButton onClick={(e) => handleImageRemove(file, e)}>X</RemoveButton>
              </FileItem>
            ))}
            {pendingImages.map((file, index) => (
              <FileItem key={index}>
                <span>{file.name}</span>
                <RemoveButton onClick={(e) => handlePendingImageRemove(file, e)}>X</RemoveButton>
              </FileItem>
            ))}
          </FileList>
        )}
        <input
          id="fileInput"
          type="file"
          multiple
          style={{ display: 'none' }}
          onChange={handleImageChange}
        />
      </FileUploadArea>
    </Section>
  );
};