import { useState, useCallback } from 'react';
import { fetchWithAuth } from '../ApiStateContext';

const TUTOR_API_BACKEND_URL = process.env.REACT_APP_TUTOR_API_BACKEND_URL;

export const useSecondBrains = (sessionId: string | null) => {
  const [pendingSecondBrains, setPendingSecondBrains] = useState<File[]>([]);
  const [uploadedSecondBrains, setUploadedSecondBrains] = useState<string[]>([]);

  const handleSecondBrainChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (sessionId) {
      await uploadSecondBrains(files, sessionId);
    } else {
      console.log('Session ID is null, adding files to pending list:', files);
      setPendingSecondBrains(prevFiles => {
        const newPendingSecondBrains = [...prevFiles, ...files];
        console.log('Updated pending second brains:', newPendingSecondBrains);
        return newPendingSecondBrains;
      });
    }
  }, [sessionId]);

  const uploadSecondBrains = async (files: File[], currentSessionId: string) => {
    const uploadedFilenames: string[] = [];
    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await fetchWithAuth(`${TUTOR_API_BACKEND_URL}/upload-second-brain/${currentSessionId}`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`Failed to upload file: ${await response.text()}`);
        }

        const data = await response.json();
        uploadedFilenames.push(data.filename);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
    setUploadedSecondBrains(prevFiles => [...prevFiles, ...uploadedFilenames]);
  };

  const uploadPendingSecondBrains = useCallback(async (currentSessionId: string) => {
    console.log('Uploading pending second brains:', pendingSecondBrains);
    if (currentSessionId && pendingSecondBrains.length > 0) {
      await uploadSecondBrains(pendingSecondBrains, currentSessionId);
      setPendingSecondBrains([]);
    }
  }, [pendingSecondBrains]);

  const handleSecondBrainRemove = async (filename: string, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!sessionId) return;

    try {
      const response = await fetchWithAuth(`${TUTOR_API_BACKEND_URL}/delete-second-brain/${sessionId}/${filename}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error(`Failed to delete file: ${await response.text()}`);
      }

      setUploadedSecondBrains(prev => prev.filter(file => file !== filename));
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handlePendingSecondBrainRemove = (file: File, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setPendingSecondBrains(prev => prev.filter(f => f !== file));
  };

  const handleSecondBrainDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    const event = {
      target: { files }
    } as unknown as React.ChangeEvent<HTMLInputElement>;
    handleSecondBrainChange(event);
  };

  return {
    pendingSecondBrains,
    setPendingSecondBrains,
    uploadedSecondBrains,
    setUploadedSecondBrains,
    handleSecondBrainChange,
    handleSecondBrainRemove,
    handlePendingSecondBrainRemove,
    handleSecondBrainDrop,
    uploadPendingSecondBrains,
  };
};