import React, { useEffect, useState } from 'react';
import { fetchWithAuth, useApiState } from '../../ApiStateContext';
import { Subject, Course, Unit, Topic, Standard } from '../../types/curriculumTypes';
import {
  Section,
  SectionTitle,
  LabeledInput,
  StyledLabel,
  CompactSelect,
  StateCurriculumValue,
  CompactInput
} from './SharedStyledComponents';

const TUTOR_DEMO_BACKEND_URL = process.env.REACT_APP_TUTOR_DEMO_BACKEND_URL;
const TUTOR_API_BACKEND_URL = process.env.REACT_APP_TUTOR_API_BACKEND_URL;

interface CurriculumConfigProps {
  sessionId: string | null;
}

export const CurriculumConfig: React.FC<CurriculumConfigProps> = ({ sessionId }) => {
  const { apiState, updateApiState } = useApiState();
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [courses, setCourses] = useState<Course[]>([]);
  const [units, setUnits] = useState<Unit[]>([]);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [standards, setStandards] = useState<Standard[]>([]);
  const [usStates, setUSStates] = useState<string[]>([]);
  const [stateCurriculumName, setStateCurriculumName] = useState<string>('');
  const [grades, setGrades] = useState<string[]>([]);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await fetch(`${TUTOR_DEMO_BACKEND_URL}/curriculum/subjects`);
        if (!response.ok) {
          throw new Error(`Error fetching subjects: ${response.status}`);
        }
        const data: Subject[] = await response.json();
        setSubjects(data);
      } catch (error) {
        console.error('Error fetching subjects:', error);
        setSubjects([]);
      }
    };
  
    fetchSubjects();
  }, []);

  const fetchCourses = async (subjectId: string) => {
    try {
      const response = await fetch(`${TUTOR_DEMO_BACKEND_URL}/curriculum/courses/${subjectId}`);
      const data = await response.json();
      setCourses(data);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  }

  const fetchUnits = async (subjectId: string) => {
    try {
      const response = await fetch(`${TUTOR_DEMO_BACKEND_URL}/curriculum/units/${subjectId}`);
      const data = await response.json();
      setUnits(data);
    } catch (error) {
      console.error('Error fetching units:', error);
    }
  };
  
  const fetchTopics = async (unitId: string) => {
    try {
      const response = await fetch(`${TUTOR_DEMO_BACKEND_URL}/curriculum/topics/${unitId}`);
      const data = await response.json();
      setTopics(data);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };
  
  const fetchStandards = async (topicId: string) => {
    try {
      const response = await fetch(`${TUTOR_DEMO_BACKEND_URL}/curriculum/standards/${topicId}`);
      const data = await response.json();
      setStandards(data);
    } catch (error) {
      console.error('Error fetching standards:', error);
    }
  };

  useEffect(() => {
    const fetchUSStates = async () => {
      try {
        const response = await fetchWithAuth(`${TUTOR_API_BACKEND_URL}/get-us-states`);
        if (!response.ok) {
          throw new Error(`Error fetching US states: ${response.status}`);
        }
        const data = await response.json();
        const stateAbbreviations = data.map((state: { abbreviation: string }) => state.abbreviation);
        setUSStates(stateAbbreviations);
      } catch (error) {
        console.error('Error fetching US states:', error);
      }
    };

    const fetchGrades = async () => {
      try {
        const response = await fetchWithAuth(`${TUTOR_API_BACKEND_URL}/get-grades`);
        if (!response.ok) {
          throw new Error(`Error fetching grades: ${response.status}`);
        }
        const data: string[] = await response.json();
        setGrades(data);
      } catch (error) {
        console.error('Error fetching grades:', error);
      }
    };

    fetchUSStates();
    fetchGrades();
  }, []);

  useEffect(() => {
    const fetchStateCurriculumName = async () => {
      if (apiState.usStateAbbreviation) {
        if (apiState.usStateAbbreviation === 'None') {
          setStateCurriculumName('None');
        } else {
          try {
            const response = await fetchWithAuth(`${TUTOR_API_BACKEND_URL}/get-state-curriculum-name/${apiState.usStateAbbreviation}`);
            if (!response.ok) {
              throw new Error(`Error fetching state curriculum name: ${response.status}`);
            }
            const data = await response.json();
            setStateCurriculumName(data.name);
          } catch (error) {
            console.error('Error fetching state curriculum name:', error);
            setStateCurriculumName('');
          }
        }
      } else {
        setStateCurriculumName('');
      }
    };

    fetchStateCurriculumName();
  }, [apiState.usStateAbbreviation]);

  const handleSubjectChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const subjectId = e.target.value;
    updateApiState({ curriculumSubjectId: subjectId, curriculumCourseId: undefined, curriculumUnitId: undefined, curriculumTopicId: undefined, curriculumStandardId: undefined }, sessionId);

    if (subjectId) {
      fetchCourses(subjectId);
      fetchUnits(subjectId);
    } else {
      setCourses([]);
      setUnits([]);
    }
    setTopics([]);
    setStandards([]);
  };

  const handleUnitChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const unitId = e.target.value;
    updateApiState({ curriculumUnitId: unitId, curriculumTopicId: undefined, curriculumStandardId: undefined }, sessionId);

    if (unitId) {
      fetchTopics(unitId);
    } else {
      setTopics([]);
    }
    setStandards([]);
  };

  const handleTopicChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const topicId = e.target.value;
    updateApiState({ curriculumTopicId: topicId, curriculumStandardId: undefined }, sessionId);

    if (topicId) {
      fetchStandards(topicId);
    } else {
      setStandards([]);
    }
  };

  return (
    <Section>
      <SectionTitle>Curriculum</SectionTitle>
      <LabeledInput>
        <StyledLabel htmlFor="grade">Grade</StyledLabel>
        <CompactSelect
          id="grade"
          name="grade"
          value={apiState.grade}
          onChange={(e) => updateApiState({ grade: e.target.value }, sessionId)}
        >
          {grades.map((grade) => (
            <option key={grade} value={grade}>
              {grade === "0" ? "None" : grade}
            </option>
          ))}
        </CompactSelect>
      </LabeledInput>
      <LabeledInput>
        <StyledLabel htmlFor="stateStandards">State</StyledLabel>
        <CompactSelect
          id="stateStandards"
          name="stateStandards"
          value={apiState.usStateAbbreviation}
          onChange={(e) => updateApiState({ usStateAbbreviation: e.target.value }, sessionId)}
        >
          <option value="None">None</option>
          {usStates.map((abbr) => (
            <option key={abbr} value={abbr}>
              {abbr}
            </option>
          ))}
        </CompactSelect>
      </LabeledInput>
      <LabeledInput>
        <StyledLabel>State Curriculum</StyledLabel>
        <StateCurriculumValue>
          {stateCurriculumName || 'N/A'}
        </StateCurriculumValue>
      </LabeledInput>
      <LabeledInput>
        <StyledLabel htmlFor="curriculumSubject">Subject</StyledLabel>
        <CompactSelect 
          id="curriculumSubject"
          name="curriculumSubject"
          value={apiState.curriculumSubjectId} 
          onChange={handleSubjectChange}
        >
          <option value="">Select Subject</option>
          {subjects.map(subject => (
            <option key={subject.id} value={subject.id}>{subject.name}</option>
          ))}
        </CompactSelect>
      </LabeledInput>
      <LabeledInput>
        <StyledLabel htmlFor="curriculumCourse">Course</StyledLabel>
        <CompactSelect 
          id="curriculumCourse"
          name="curriculumCourse"
          value={apiState.curriculumCourseId} 
          onChange={(e) => updateApiState({ curriculumCourseId: e.target.value }, sessionId)}
        >
          <option value="">Select Course</option>
          {courses.map(course => (
            <option key={course.id} value={course.id}>{course.name}</option>
          ))}
        </CompactSelect>
      </LabeledInput>
      <LabeledInput>
        <StyledLabel htmlFor="curriculumUnit">Unit</StyledLabel>
        <CompactSelect 
          id="curriculumUnit"
          name="curriculumUnit"
          value={apiState.curriculumUnitId} 
          onChange={handleUnitChange}
        >
          <option value="">Select Unit</option>
          {units.map(unit => (
            <option key={unit.id} value={unit.id}>{unit.name}</option>
          ))}
        </CompactSelect>
      </LabeledInput>
      <LabeledInput>
        <StyledLabel htmlFor="curriculumTopic">Topic</StyledLabel>
        <CompactSelect 
          id="curriculumTopic"
          name="curriculumTopic"
          value={apiState.curriculumTopicId} 
          onChange={handleTopicChange}
        >
          <option value="">Select Topic</option>
          {topics.map(topic => (
            <option key={topic.id} value={topic.id}>{topic.name}</option>
          ))}
        </CompactSelect>
      </LabeledInput>
      <LabeledInput>
        <StyledLabel htmlFor="curriculumStandard">Standard</StyledLabel>
        <CompactSelect 
          id="curriculumStandard"
          name="curriculumStandard"
          value={apiState.curriculumStandardId} 
          onChange={(e) => updateApiState({ curriculumStandardId: e.target.value }, sessionId)}
        >
          <option value="">Select Standard</option>
          {standards.map(standard => (
            <option key={standard.id} value={standard.id}>{standard.description}</option>
          ))}
        </CompactSelect>
      </LabeledInput>
      <LabeledInput>
        <StyledLabel htmlFor="curriculumTeacherTopic">Teacher Topic</StyledLabel>
        <CompactInput
          id="curriculumTeacherTopic"
          name="curriculumTeacherTopic"
          value={apiState.curriculumTeacherTopic}
          onChange={(e) => updateApiState({ curriculumTeacherTopic: e.target.value }, sessionId)}
        />
      </LabeledInput>
    </Section>
  );
};