import React, { useState, useEffect, useCallback } from 'react';
import { fetchWithAuth, useApiState } from '../../ApiStateContext';
import {
  Section,
  SectionTitle,
  LabeledInput,
  StyledLabel,
  CompactInput,
  CompactSelect,
  Textarea
} from './SharedStyledComponents';

const TUTOR_API_BACKEND_URL = process.env.REACT_APP_TUTOR_API_BACKEND_URL;

interface EngagementMethodsConfigProps {
  sessionId: string | null;
}

interface EngagementMethod {
  name: string;
  description: string;
  required: boolean;
  default?: string;
}

interface EngagementMethodInput {
  name: string;
  description: string;
  required: boolean;
  default?: string;
}

export const EngagementMethodsConfig: React.FC<EngagementMethodsConfigProps> = ({ sessionId }) => {
  const { apiState, updateApiState } = useApiState();
  const [engagementMethods, setEngagementMethods] = useState<EngagementMethod[]>([]);
  const [engagementMethodInputs, setEngagementMethodInputs] = useState<EngagementMethodInput[]>([]);
  const [engagementMethodPrompt, setEngagementMethodPrompt] = useState<string>('');

  useEffect(() => {
    const fetchEngagementMethods = async () => {
      try {
        const response = await fetchWithAuth(`${TUTOR_API_BACKEND_URL}/get-engagement-methods`);
        if (response.ok) {
          const data = await response.json();
          setEngagementMethods(data);
        }
      } catch (error) {
        console.error('Error fetching engagement methods:', error);
      }
    };

    fetchEngagementMethods();
  }, []);

  const fetchEngagementMethodInputs = async (engagementMethod: string) => {
    try {
      const response = await fetchWithAuth(`${TUTOR_API_BACKEND_URL}/get-engagement-method-inputs/${engagementMethod}`);
      if (response.ok) {
        const data = await response.json();
        setEngagementMethodInputs(data);
      }
    } catch (error) {
      console.error('Error fetching engagement method inputs:', error);
    }
  };

  const fetchEngagementMethodPrompt = useCallback(async (engagementMethod: string) => {
    try {
      const response = await fetchWithAuth(`${TUTOR_API_BACKEND_URL}/get-prompt/${engagementMethod}`);
      if (response.ok) {
        const data = await response.json();
        setEngagementMethodPrompt(data.prompt);
        updateApiState({ engagementMethodPrompt: data.prompt }, sessionId);
      }
    } catch (error) {
      console.error('Error fetching engagement method prompt:', error);
    }
  }, [updateApiState, sessionId]);

  const handleEngagementMethodChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newEngagementMethod = e.target.value;
    updateApiState({ engagementMethod: newEngagementMethod }, sessionId);
    await fetchEngagementMethodInputs(newEngagementMethod);
    await fetchEngagementMethodPrompt(newEngagementMethod);
    updateApiState({ engagementMethodInputs: {} }, sessionId);
  };

  const handleEngagementMethodInputChange = (name: string, value: string) => {
    const newInputs = { ...apiState.engagementMethodInputs, [name]: value };
    updateApiState({ engagementMethodInputs: newInputs }, sessionId);
  };

  const handleEngagementMethodPromptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newPrompt = e.target.value;
    setEngagementMethodPrompt(newPrompt);
    updateApiState({ engagementMethodPrompt: newPrompt }, sessionId);
  };

  useEffect(() => {
    if (apiState.engagementMethod) {
      fetchEngagementMethodPrompt(apiState.engagementMethod);
    }
  }, [apiState.engagementMethod, fetchEngagementMethodPrompt]);

  return (
    <Section>
      <SectionTitle>Engagement Methods</SectionTitle>
      <LabeledInput>
        <StyledLabel htmlFor="engagementMethod">Engagement Method</StyledLabel>
        <CompactSelect
          id="engagementMethod"
          name="engagementMethod"
          value={apiState.engagementMethod}
          onChange={handleEngagementMethodChange}
        >
          {engagementMethods.map((method, index) => (
            <option key={index} value={method.name}>{method.name}</option>
          ))}
        </CompactSelect>
      </LabeledInput>

      {engagementMethodInputs.length > 0 && (
        <div>
          {engagementMethodInputs.map((input, index) => (
            <LabeledInput key={index}>
              <StyledLabel htmlFor={`engagementMethodInput-${index}`}>
                {input.name}{input.required ? '*' : ''}
              </StyledLabel>
              <CompactInput
                id={`engagementMethodInput-${index}`}
                name={`engagementMethodInput-${index}`}
                placeholder={`${input.description}...`}
                value={apiState.engagementMethodInputs[input.name] || ''}
                onChange={(e) => handleEngagementMethodInputChange(input.name, e.target.value)}
              />
            </LabeledInput>
          ))}
        </div>
      )}

      <LabeledInput style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
        <StyledLabel htmlFor="engagementMethodPrompt" style={{ width: '200px' }}>Engagement Method Prompt</StyledLabel>
        <Textarea
          id="engagementMethodPrompt"
          name="engagementMethodPrompt"
          placeholder="Enter engagement method prompt..."
          value={engagementMethodPrompt}
          onChange={handleEngagementMethodPromptChange}
          rows={5}
          style={{ width: '100%' }}
        />
      </LabeledInput>
    </Section>
  );
};
