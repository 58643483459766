import React from 'react';
import { useApiState } from '../../ApiStateContext';
import styled from 'styled-components';
import {
  Section,
  SectionTitle,
  StyledLabel,
  Textarea,
  HorizontalRadioGroup,
  Label
} from './SharedStyledComponents';

const PromptContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

interface PromptsConfigProps {
  sessionId: string | null;
}

export const PromptsConfig: React.FC<PromptsConfigProps> = ({ sessionId }) => {
  const { apiState, updateApiState } = useApiState();

  const handleRadioChange = (value: boolean) => {
    updateApiState({ useCustomSystemPrompt: value }, sessionId);
    if (value) {
      updateApiState({customSystemPrompt: apiState.lastCustomSystemPrompt}, sessionId);
      console.log('1 apiState.lastCustomSystemPrompt:', apiState.lastCustomSystemPrompt);
    } else {
      updateApiState({lastCustomSystemPrompt: apiState.customSystemPrompt}, sessionId);
      updateApiState({customSystemPrompt: ''}, sessionId);
      console.log('2 apiState.customSystemPrompt:', apiState.customSystemPrompt);
      console.log('2 apiState.lastCustomSystemPrompt:', apiState.lastCustomSystemPrompt);
    }
  };

  return (
    <Section>
      <SectionTitle>Prompts</SectionTitle>
      <PromptContainer>
        <StyledLabel>Prompt Type</StyledLabel>
        <HorizontalRadioGroup>
          <Label>
            <input
              type="radio"
              name="promptType"
              value="guidelines"
              checked={!apiState.useCustomSystemPrompt}
              onChange={() => handleRadioChange(false)}
            />
            Guidelines
          </Label>
          <Label>
            <input
              type="radio"
              name="promptType"
              value="customSystemPrompt"
              checked={apiState.useCustomSystemPrompt}
              onChange={() => handleRadioChange(true)}
            />
            Custom System Prompt
          </Label>
        </HorizontalRadioGroup>
      </PromptContainer>
      {!apiState.useCustomSystemPrompt ? (
        <PromptContainer>
          <Textarea
            id="guidelines"
            name="guidelines"
            value={apiState.guidelines}
            onChange={(e) => updateApiState({ guidelines: e.target.value }, sessionId)}
            placeholder="Enter guidelines..."
            rows={5}
          />
        </PromptContainer>
      ) : (
        <PromptContainer>
          <Textarea
            id="customSystemPrompt"
            name="customSystemPrompt"
            value={apiState.customSystemPrompt}
            onChange={(e) => updateApiState({ customSystemPrompt: e.target.value }, sessionId)}
            placeholder="Enter custom system prompt..."
            rows={5}
          />
        </PromptContainer>
      )}
      <SectionTitle>Special Instructions</SectionTitle>
      <PromptContainer>
        <Textarea
          id="specialInstructions"
          name="specialInstructions"
          value={apiState.specialInstructions}
          onChange={(e) => updateApiState({ specialInstructions: e.target.value }, sessionId)}
          placeholder="Enter special instructions..."
          rows={5}
        />
      </PromptContainer>
    </Section>
  );
};