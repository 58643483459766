import React from 'react';
import { useApiState } from '../../ApiStateContext';
import {
  Section,
  SectionTitle,
  LabeledInput,
  StyledLabel,
  CompactInput
} from './SharedStyledComponents';

interface StudentInterestsConfigProps {
  sessionId: string | null;
}

export const StudentInterestsConfig: React.FC<StudentInterestsConfigProps> = ({ sessionId }) => {
  const { apiState, updateApiState } = useApiState();

  const handleFavoriteChange = (field: 'favoriteSport' | 'favoriteCelebrity' | 'favoriteTVShow', value: string) => {
    const updatedState = { [field]: value };
    updateApiState(updatedState, sessionId);

    const favoriteSport = field === 'favoriteSport' ? value : apiState.favoriteSport || "[unknown]";
    const favoriteCelebrity = field === 'favoriteCelebrity' ? value : apiState.favoriteCelebrity || "[unknown]";
    const favoriteTVShow = field === 'favoriteTVShow' ? value : apiState.favoriteTVShow || "[unknown]";

    const studentInterests = `Favorite Sport: ${favoriteSport} Favorite Celebrity: ${favoriteCelebrity} Favorite TV Show: ${favoriteTVShow}`;
    updateApiState({ studentInterests }, sessionId);
  };

  return (
    <Section>
      <SectionTitle>Student Interests</SectionTitle>
      <LabeledInput>
        <StyledLabel htmlFor="favoriteSport">Favorite Sport</StyledLabel>
        <CompactInput 
          id="favoriteSport"
          name="favoriteSport"
          placeholder="Favorite Sport" 
          value={apiState.favoriteSport} 
          onChange={(e) => handleFavoriteChange('favoriteSport', e.target.value)}
        />
      </LabeledInput>
      <LabeledInput>
        <StyledLabel htmlFor="favoriteCelebrity">Favorite Celebrity</StyledLabel>
        <CompactInput 
          id="favoriteCelebrity"
          name="favoriteCelebrity"
          placeholder="Favorite Celebrity" 
          value={apiState.favoriteCelebrity} 
          onChange={(e) => handleFavoriteChange('favoriteCelebrity', e.target.value)}
        />
      </LabeledInput>
      <LabeledInput>
        <StyledLabel htmlFor="favoriteTVShow">Favorite TV Show</StyledLabel>
        <CompactInput 
          id="favoriteTVShow"
          name="favoriteTVShow"
          placeholder="Favorite TV Show" 
          value={apiState.favoriteTVShow} 
          onChange={(e) => handleFavoriteChange('favoriteTVShow', e.target.value)}
        />
      </LabeledInput>
    </Section>
  );
};