import React from 'react';
import {
  Section,
  SectionTitle,
  FileUploadArea,
  FileList,
  FileItem,
  RemoveButton
} from './SharedStyledComponents';

interface SecondBrainsConfigProps {
  sessionId: string | null;
  pendingSecondBrains: File[];
  uploadedSecondBrains: string[];
  handleSecondBrainChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSecondBrainRemove: (filename: string, e: React.MouseEvent<HTMLButtonElement>) => void;
  handlePendingSecondBrainRemove: (file: File, e: React.MouseEvent<HTMLButtonElement>) => void;
  handleSecondBrainDrop: (e: React.DragEvent<HTMLDivElement>) => void;
}

export const SecondBrainsConfig: React.FC<SecondBrainsConfigProps> = ({
  sessionId,
  pendingSecondBrains,
  uploadedSecondBrains,
  handleSecondBrainChange,
  handleSecondBrainRemove,
  handlePendingSecondBrainRemove,
  handleSecondBrainDrop
}) => {
  return (
    <Section>
      <SectionTitle>Second Brains</SectionTitle>
      <FileUploadArea
        onDrop={handleSecondBrainDrop}
        onDragOver={(e) => e.preventDefault()}
        onClick={() => {
          document.getElementById('secondBrainsFileInput')?.click();
        }}
      >
        {uploadedSecondBrains.length === 0 && pendingSecondBrains.length === 0 ? (
          "Drag and drop files here or click to upload"
        ) : (
          <FileList>
            {uploadedSecondBrains.map((file, index) => (
              <FileItem key={index}>
                <span>{file}</span>
                <RemoveButton onClick={(e) => handleSecondBrainRemove(file, e)}>X</RemoveButton>
              </FileItem>
            ))}
            {pendingSecondBrains.map((file, index) => (
              <FileItem key={index}>
                <span>{file.name}</span>
                <RemoveButton onClick={(e) => handlePendingSecondBrainRemove(file, e)}>X</RemoveButton>
              </FileItem>
            ))}
          </FileList>
        )}
        <input
          id="secondBrainsFileInput"
          type="file"
          multiple
          style={{ display: 'none' }}
          onChange={handleSecondBrainChange}
        />
      </FileUploadArea>
    </Section>
  );
};