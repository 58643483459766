export const avatarMapping = {
  10: 'Albert Einstein',
  25: 'Cleopatra',
  30: 'William Shakespeare',
  39: 'Marie Curie',
  41: 'Isaac Newton',
  42: 'Jane Austen',
  43: 'Maya Angelou',
  49: 'Henry Ford',
  51: 'Abraham Lincoln',
  55: 'Alexander the Great',
  56: 'Mark Twain',
  57: 'George Washington',
  65: 'Christopher Columbus',
  71: 'Napoleon Bonaparte',
  91: 'Thomas Edison',
  93: 'Amelia Earhart',
};


// Sort the avatars by name
const sortedAvatars = Object.entries(avatarMapping).sort(([, nameA], [, nameB]) => nameA.localeCompare(nameB));

export const avatarNames = sortedAvatars.map(([, name]) => name);
export const avatarIds = sortedAvatars.map(([id]) => Number(id));