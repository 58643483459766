export enum LLMType {
  Claude = 'Claude',
  OpenAI = 'OpenAI',
  InceptAI = 'InceptAI'
}

export enum ClaudeModel {
  Haiku = 'claude-3-haiku-20240307',
  Sonnet = 'claude-3-5-sonnet-20240620',
  Opus = 'claude-3-opus-20240229'
}

export enum OpenAIModel {
  GPT35Turbo = 'gpt-3.5-turbo',
  GPT4 = 'gpt-4',
  GPT4Turbo = 'gpt-4-turbo',
  GPT4o = 'gpt-4o',
  GPT4oMini = 'gpt-4o-mini'
}

export enum InceptAIModel {
  InceptK12 = 'Incept K-12'
}

export const modelSupportsFiles = (model: OpenAIModel | ClaudeModel): boolean => {
  const modelsSupportingFiles = [
    OpenAIModel.GPT4oMini,
    OpenAIModel.GPT4o,
    OpenAIModel.GPT4Turbo,
    ClaudeModel.Haiku,
    ClaudeModel.Sonnet,
    ClaudeModel.Opus
  ];
  return modelsSupportingFiles.includes(model);
};