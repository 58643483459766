import React, { useState } from 'react';
import { fetchWithAuth, useApiState } from '../../ApiStateContext';
import { LLMType, OpenAIModel, ClaudeModel, InceptAIModel } from '../../types/llmTypes';
import { avatarNames, avatarIds } from '../../types/avatarTypes';
import { generateIntroMessage } from '../../App';
import {
  Section,
  SectionTitle,
  LabeledInput,
  StyledLabel,
  CompactInput,
  CompactSelect,
  CompactHorizontalGroup,
  HorizontalRadioGroup,
  Label,
  SliderVariableControls,
  SliderVariableSlider,
  SliderVariableInput,
  SizeCustomizerInput
} from './SharedStyledComponents';

const TUTOR_API_BACKEND_URL = process.env.REACT_APP_TUTOR_API_BACKEND_URL;

interface CoreConfigProps {
  sessionId: string | null;
}

export const CoreConfig: React.FC<CoreConfigProps> = ({ sessionId }) => {
  const { apiState, updateApiState } = useApiState();
  const [selectedAvatar, setSelectedAvatar] = useState(apiState.avatarId);
  const [speakingRate, setSpeakingRate] = useState(1.0);

  const logMin = Math.log(0.5);
  const logMax = Math.log(3.0);
  const logMid = Math.log(1.0);

  const customScale = (value: number) => {
    const logValue = Math.log(value);
    if (logValue <= logMid) {
      return (logValue - logMin) / (logMid - logMin) * 0.5;
    } else {
      return 0.5 + (logValue - logMid) / (logMax - logMid) * 0.5;
    }
  };

  const inverseCustomScale = (value: number) => {
    if (value <= 0.5) {
      return Math.exp(logMin + value * (logMid - logMin) / 0.5);
    } else {
      return Math.exp(logMid + (value - 0.5) * (logMax - logMid) / 0.5);
    }
  };

  const handleLLMChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLLM = event.target.value as LLMType;
    updateApiState({ currentLLM: newLLM }, sessionId);

    if (newLLM === LLMType.OpenAI) {
      updateApiState({ currentModel: apiState.currentOpenAIModel }, sessionId);
    } else {
      updateApiState({ currentModel: apiState.currentClaudeModel }, sessionId);
    }
  };

  const handleModelChange = (model: OpenAIModel | ClaudeModel | InceptAIModel) => {
    if (apiState.currentLLM === LLMType.OpenAI) {
      updateApiState({ currentOpenAIModel: model as OpenAIModel, currentModel: model }, sessionId);
    } else if (apiState.currentLLM === LLMType.Claude) {
      updateApiState({ currentClaudeModel: model as ClaudeModel, currentModel: model }, sessionId);
    } else {
      updateApiState({ currentInceptAIModel: model as InceptAIModel, currentModel: model }, sessionId);
    }
  };

  const handleToggleVideo = async () => {
    if (sessionId) {
      try {
        const response = await fetchWithAuth(`${TUTOR_API_BACKEND_URL}/toggle-avatar-video/${sessionId}`, {
          method: 'POST',
        });

        if (response.ok) {
          updateApiState({ isVideoEnabled: !apiState.isVideoEnabled }, sessionId);
        } else {
          console.error('Failed to toggle avatar video:', await response.text());
        }
      } catch (error) {
        console.error('Error toggling avatar video:', error);
      }
    } else {
      updateApiState({ isVideoEnabled: !apiState.isVideoEnabled }, sessionId);
    }
  };

  const handleToggleAudio = async () => {
    if (sessionId) {
      try {
        const response = await fetchWithAuth(`${TUTOR_API_BACKEND_URL}/toggle-avatar-voice/${sessionId}`, {
          method: 'POST',
        });

        if (response.ok) {
          updateApiState({ isAudioEnabled: !apiState.isAudioEnabled }, sessionId);
        } else {
          console.error('Failed to toggle avatar voice:', await response.text());
        }
      } catch (error) {
        console.error('Error toggling avatar voice:', error);
      }
    } else {
      updateApiState({ isAudioEnabled: !apiState.isAudioEnabled }, sessionId);
    }
  };

  const handleToggleText = async () => {
    if (sessionId) {
      try {
        const response = await fetchWithAuth(`${TUTOR_API_BACKEND_URL}/toggle-text-box/${sessionId}`, {
          method: 'POST',
        });
        
        if (response.ok) {
          updateApiState({ isTextEnabled: !apiState.isTextEnabled }, sessionId);
        } else {
          console.error('Failed to toggle text box:', await response.text());
        }
      } catch (error) {
          console.error('Error toggling text box:', error);
      }
    } else {
      updateApiState({ isTextEnabled: !apiState.isTextEnabled }, sessionId);
    }
  };

  const handleToggleMicrophone = async () => {
    if (sessionId) {
          try {
            const response = await fetchWithAuth(`${TUTOR_API_BACKEND_URL}/toggle-speech-recognition/${sessionId}`, {
              method: 'POST',
            });
      
            if (response.ok) {
              updateApiState({ microphoneMuted: !apiState.microphoneMuted }, sessionId);
            } else {
              console.error('Failed to toggle speech recognition:', await response.text());
            }
          } catch (error) {
            console.error('Error toggling speech recognition:', error);
          }
    } else {
      updateApiState({ microphoneMuted: !apiState.microphoneMuted }, sessionId);
    }
  };

  const handleToggleBasicChat = async () => {
    if (sessionId) {
      updateApiState({ isBasicChat: !apiState.isBasicChat }, sessionId);
    } else {
      updateApiState({ isBasicChat: !apiState.isBasicChat }, sessionId);
    }
  };

  const handleAvatarChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newAvatarId = Number(e.target.value);
    setSelectedAvatar(newAvatarId);
    updateApiState({ avatarId: newAvatarId }, sessionId);

    if (sessionId) {
      try {
        const response = await fetchWithAuth(`${TUTOR_API_BACKEND_URL}/change-avatar/${sessionId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ avatarId: newAvatarId }),
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Avatar changed:', data);
          await generateIntroMessage(sessionId);
        } else {
          const errorText = await response.text();
          console.error('Error changing avatar:', response.status, errorText);
          throw new Error(`Server responded with ${response.status}: ${errorText}`);
        }
      } catch (error) {
        console.error("Error changing avatar:", error);
      }
    }
  };

  const handleSpeakingRateChange = async (newRate: number) => {
    newRate = Math.min(Math.max(inverseCustomScale(newRate), 0.5), 3.0);
    setSpeakingRate(newRate);
    updateApiState({ speakingRate: newRate }, sessionId);

    if (sessionId) {
      try {
        const response = await fetchWithAuth(`${TUTOR_API_BACKEND_URL}/change-speaking-rate/${sessionId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ speakingRate: newRate }),
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error('Error changing speaking rate:', response.status, errorText);
          throw new Error(`Server responded with ${response.status}: ${errorText}`);
        }
      } catch (error) {
        console.error("Error changing speaking rate:", error);
      }
    }
  };

  const handleVerbosityChange = async (newVerbosity: number) => {
    newVerbosity = Math.min(Math.max(newVerbosity, 0.0), 1.0);
    updateApiState({ verbosity: newVerbosity }, sessionId);
  };

  const handleToneChange = async (newTone: number) => {
    newTone = Math.min(Math.max(newTone, 0.0), 1.0);
    updateApiState({ tone: newTone }, sessionId);   
  };

  const handleLayoutChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!sessionId) return;
    const newLayout = e.target.value as 'horizontal' | 'vertical';
    updateApiState({ layout: newLayout }, sessionId);
    try {
      const response = await fetchWithAuth(`${TUTOR_API_BACKEND_URL}/set-layout/${sessionId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ layout: newLayout }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Layout changed:', data);
      } else {
        console.error('Error changing layout:', await response.text());
      }
    } catch (error) {
      console.error("Error changing layout:", error);
    }
  };

  const handleColorProfileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!sessionId) return;
    const newColorProfile = e.target.value === 'Dark Mode' ? 'dark-mode' : 'default';
    updateApiState({ colorProfile: newColorProfile }, sessionId);
    try {
      const response = await fetchWithAuth(`${TUTOR_API_BACKEND_URL}/set-color-profile/${sessionId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ colorProfile: newColorProfile }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Color profile changed:', data);
      } else {
        console.error('Error changing color profile:', await response.text());
      }
    } catch (error) {
      console.error("Error changing color profile:", error);
    }
  };

  return (
    <Section>
      <SectionTitle>Core Config</SectionTitle>
      <LabeledInput>
        <StyledLabel htmlFor="userName">User Name</StyledLabel>
        <CompactInput
          id="userName"
          name="userName"
          placeholder="Enter user name..."
          value={apiState.userName}
          onChange={(e) => updateApiState({ userName: e.target.value }, sessionId)}
        />
      </LabeledInput>
      
      <CompactHorizontalGroup>
        <HorizontalRadioGroup>
          <Label>
            <input 
              type="radio" 
              name="llm" 
              value={LLMType.InceptAI} 
              checked={apiState.currentLLM === LLMType.InceptAI}
              onChange={(e) => handleLLMChange(e)}
            />
            InceptAI
          </Label>
          <Label>
            <input 
              type="radio" 
              name="llm" 
              value={LLMType.OpenAI} 
              checked={apiState.currentLLM === LLMType.OpenAI}
              onChange={(e) => handleLLMChange(e)}
            />
            OpenAI
          </Label>
          <Label>
            <input 
              type="radio" 
              name="llm" 
              value={LLMType.Claude} 
              checked={apiState.currentLLM === LLMType.Claude}
              onChange={(e) => handleLLMChange(e)}
            />
            Claude
          </Label>
        </HorizontalRadioGroup>
        <CompactSelect
          id="modelSelect"
          name="modelSelect"
          value={apiState.currentModel}
          style={{ width: '140px' }}
          onChange={(e) => handleModelChange(e.target.value as OpenAIModel | ClaudeModel | InceptAIModel)}
        >
          {apiState.currentLLM === LLMType.OpenAI
            ? Object.values(OpenAIModel).map(model => (
                <option key={model} value={model}>{model}</option>
              ))
            : apiState.currentLLM === LLMType.Claude
              ? Object.values(ClaudeModel).map(model => (
                  <option key={model} value={model}>{model}</option>
                ))
              : Object.values(InceptAIModel).map(model => (
                  <option key={model} value={model}>{model}</option>
                ))
          }
        </CompactSelect>
      </CompactHorizontalGroup>

      <CompactHorizontalGroup>
        <HorizontalRadioGroup style={{ width: '120px' }}>
          <Label>
            <input 
              type="radio" 
              name="avatar" 
              value="Alpha" 
              checked={apiState.avatar === "Alpha"}
              onChange={() => updateApiState({ avatar: "Alpha" }, sessionId)}
            />
            Alpha
          </Label>
          <Label>
            <input 
              type="radio" 
              name="avatar" 
              value="D-ID" 
              checked={apiState.avatar === "D-ID"}
              onChange={() => updateApiState({ avatar: "D-ID" }, sessionId)}
              disabled
            />
            D-ID
          </Label>
        </HorizontalRadioGroup>
        <CompactSelect
          id="avatarSelect"
          name="avatarSelect"
          value={selectedAvatar}
          onChange={handleAvatarChange}
        >
          {avatarNames.map((name, index) => (
            <option key={index} value={avatarIds[index]}>{name}</option>
          ))}
        </CompactSelect>
      </CompactHorizontalGroup>

      <LabeledInput>
        <StyledLabel>Speaking Rate</StyledLabel>
        <SliderVariableControls>
          <SliderVariableSlider
            type="range"
            min={0}
            max={1}
            step={0.01} // Adjust step for smoothness
            value={customScale(speakingRate)}
            onChange={(e) => handleSpeakingRateChange(parseFloat(e.target.value))}
          />
          <SliderVariableInput
            type="number"
            min="0.5"
            max="3.0"
            step="0.01"
            value={speakingRate.toFixed(2)}
            onChange={(e) => handleSpeakingRateChange(parseFloat(e.target.value))}
          />
        </SliderVariableControls>
      </LabeledInput>

      <LabeledInput>
        <StyledLabel>Verbosity</StyledLabel>
        <SliderVariableControls>
          <SliderVariableSlider
            type="range"
            min="0.0"
            max="1.0"
            step="0.01"
            value={apiState.verbosity}
            onChange={(e) => handleVerbosityChange(parseFloat(e.target.value))}
          />
          <SliderVariableInput
            type="number"
            min="0.0"
            max="1.0"
            step="0.01"
            value={apiState.verbosity.toFixed(2)}
            onChange={(e) => handleVerbosityChange(parseFloat(e.target.value))}
          />
        </SliderVariableControls>
      </LabeledInput>

      <LabeledInput>
        <StyledLabel>Tone</StyledLabel>
        <SliderVariableControls>
          <SliderVariableSlider
            type="range"
            min="0.0"
            max="1.0"
            step="0.01"
            value={apiState.tone}
            onChange={(e) => handleToneChange(parseFloat(e.target.value))}
          />
          <SliderVariableInput
            type="number"
            min="0.0"
            max="1.0"
            step="0.01"
            value={apiState.tone.toFixed(2)}
            onChange={(e) => handleToneChange(parseFloat(e.target.value))}
          />
        </SliderVariableControls>
      </LabeledInput>

      <StyledLabel>I/O Options</StyledLabel>
      <CompactHorizontalGroup>
        <Label htmlFor="videoEnabled">
          <input 
            id="videoEnabled"
            type="checkbox" 
            checked={apiState.isVideoEnabled}
            onChange={handleToggleVideo}
          />
          Video
        </Label>
        <Label htmlFor="audioEnabled">
          <input 
            id="audioEnabled"
            type="checkbox" 
            checked={apiState.isAudioEnabled}
            onChange={handleToggleAudio}
          />
          Audio
        </Label>
        <Label htmlFor="textEnabled">
          <input 
            id="textEnabled"
            type="checkbox" 
            checked={apiState.isTextEnabled}
            onChange={handleToggleText}
          />
          Text
        </Label>
        <Label htmlFor="microphoneMuted">
            <input
                id="microphoneMuted"
                type="checkbox"
                checked={!apiState.microphoneMuted}
                onChange={handleToggleMicrophone}
            />
            Mic
        </Label>
        <Label htmlFor="isBasicChat">
            <input
                id="isBasicChat"
                type="checkbox"
                checked={apiState.isBasicChat}
                onChange={handleToggleBasicChat}
            />
            Basic Chat
        </Label>
      </CompactHorizontalGroup>
      <CompactHorizontalGroup>
        <StyledLabel>Layout</StyledLabel>
        <Label>
          <input
            type="radio"
            name="layout"
            value="vertical"
            checked={apiState.layout === 'vertical'}
            onChange={handleLayoutChange}
          />
          Vertical
        </Label>
        <Label>
          <input
            type="radio"
            name="layout"
            value="horizontal"
            checked={apiState.layout === 'horizontal'}
            onChange={handleLayoutChange}
          />
          Horizontal
        </Label>
      </CompactHorizontalGroup>
      <CompactHorizontalGroup>
        <StyledLabel>Color Profile</StyledLabel>
        <Label>
          <input
            type="radio"
            name="colorProfile"
            value="Default"
            checked={apiState.colorProfile === 'default'}
            onChange={handleColorProfileChange}
          />
          Default
        </Label>
        <Label>
          <input
            type="radio"
            name="colorProfile"
            value="Dark Mode"
            checked={apiState.colorProfile === 'dark-mode'}
            onChange={handleColorProfileChange}
          />
          Dark Mode
        </Label>
      </CompactHorizontalGroup>
      <LabeledInput>
        <StyledLabel>Custom Video Size</StyledLabel>
        <CompactHorizontalGroup>
          <Label>Width</Label>
          <SizeCustomizerInput
            type="text"
            style={{ marginRight: '18px' }}
            value={apiState.customVideoWidth}
            onChange={(e) => updateApiState({ customVideoWidth: e.target.value }, sessionId)}
          />
          <Label>Height</Label>
          <SizeCustomizerInput
            type="text"
            value={apiState.customVideoHeight}
            onChange={(e) => updateApiState({ customVideoHeight: e.target.value }, sessionId)}
          />
        </CompactHorizontalGroup>
      </LabeledInput>
      <LabeledInput>
        <StyledLabel>Custom Video Fit</StyledLabel>
        <CompactSelect
          name="customVideoObjectFit"
          value={apiState.customVideoObjectFit}
          onChange={(e) => updateApiState({ customVideoObjectFit: e.target.value as "contain" | "cover" | "" }, sessionId)}
        >
            <option value={""}>Default</option>
            <option value={"contain"}>Contain</option>
            <option value={"cover"}>Cover</option>
        </CompactSelect>
      </LabeledInput>
      <LabeledInput>
        <StyledLabel>Custom Message History Size</StyledLabel>
        <CompactHorizontalGroup>
          <Label>Width</Label>
          <SizeCustomizerInput
            type="text"
            style={{ marginRight: '18px' }}
            value={apiState.customMessageHistoryWidth}
            onChange={(e) => updateApiState({ customMessageHistoryWidth: e.target.value }, sessionId)}
          />
          <Label>Height</Label>
          <SizeCustomizerInput
            type="text"
            value={apiState.customMessageHistoryHeight}
            onChange={(e) => updateApiState({ customMessageHistoryHeight: e.target.value }, sessionId)}
          />
        </CompactHorizontalGroup>
      </LabeledInput>
    </Section>
  );
};